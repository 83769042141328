import { getLastNotiQuantity, getRealtimeNotiQuantity } from "page/Home/home.reducer";
import { useEffect } from "react";
import useWebSocket from "react-use-websocket";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { LOCAL_STORAGE } from "utils/contants";

export const useWebsocket = () => {
    return useWebSocket(`${process.env.REACT_APP_SOCKET_URL}`, {
        queryParams: {
            ["authorization"]: localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN) || "",
        },
        share: true,
    });
};

export const socketEvtNames = {
    CHANGE_RICHCAP_PRICE: "changeRichcapPrice",
    CHAT_ROOM_ROUTER: "chatRoomRouter",
    CHAT_P2_ROUTER: "chatP2PRouter",
    CONNECT: "connect",
    DISCONNECT: "disconnect",
    DEFAULT: "default",

    //Noti
    NOTIFICATION: "NOTIFICATION",
};

const SocketNoti = ({ children }: any) => {
    const { lastNotiQuantity, realTimeNotiQuantity } = useAppSelector((state) => state.homeReducer);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getLastNotiQuantity());
        dispatch(getRealtimeNotiQuantity());
    }, []);
    const { lastJsonMessage }: any = useWebsocket();

    useEffect(() => {
        if (lastJsonMessage?.type === socketEvtNames.NOTIFICATION) {
            console.log("hehe", lastNotiQuantity, realTimeNotiQuantity);
            dispatch(getRealtimeNotiQuantity());
        }
    }, [lastJsonMessage]);

    return <>{children}</>;
};

export default SocketNoti;
