import { createTheme } from "./utils";
const baseTheme = createTheme({
    primary: "#FFFFFF",
    secondary: "#F0F6FF",
    textBase: "#242B38",
    thirdColor: "#E9EBEE",
    colorADAFC4: "#ADAFC4",
    color2AB7F1: "#2AB7F1",
    colorBgHashTag: "#F0F6FF",
    colorTextHashTag: "#4A83F7",
    color2F49CD: "#2F49CD",
    textDesPost: "#000000",
    colorBgFocusHashTag: "#E3EEFF",
    textValue: "#12151B",
    textCategory: "#373F54",
    colorModal: "#FFFFFF",

    bgNoti: "#E3EEFF",
    bgRead: "#ffffff",
    mainNoti: "#ffffff",

    //color modal
    textClose: "#5E6272",
    bgIconModal: "#F0F6FF",
    textTypePosting: "#4A83F7",
    textDisable: "#ADAFC4",
    colorBgBtnDisable: "#E9EBEE",
    lineTab: "#EFEFEF",
    bgItemStatus: "#E3EEFF",
});
export default baseTheme;

export const THEME = {
    LIGHT: "light",
    DARK: "dark",
};
