import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import instance, { BaseUploadMediaToS3 } from "../../config/axios.config";
import { IParametersCreatePost, IParametersUpdatePost, IResponseCreatePost } from "../../types/posting.type";
// import blobToBuffer from "blob-to-buffer"

import { Buffer } from "buffer";
import { convertFileToArrayBuffer, TYPE_PUBLIC_POSTING } from "../../utils/contants";
import { IPost, IResponsePostDetail } from "../../types/post.type";
import { getBodyUpdate } from "./posting.constant";
import { searchPost } from "./../Home/home.reducer";
import { toast } from "react-toastify";
import { PostService, S3FileService } from "services";

const initFiles: any[] = []; // init default 5 empty files

interface InitStateModel {
    loading: boolean;
    files: any[];
    publicType: number;
    title: string;
    content: string;
    category: string;
    medias?: string[];
    isOpenSub: boolean;
    totalFilesSize: number;
}

const initState: InitStateModel = {
    loading: false,
    files: initFiles,
    publicType: TYPE_PUBLIC_POSTING.PUBLIC,
    title: "",
    content: "",
    category: "",
    medias: [],
    isOpenSub: false,
    totalFilesSize: 0,
};

export const createPost = createAsyncThunk(
    "posting/create-post",
    async (body: IParametersCreatePost, { getState, dispatch }) => {
        let bodyRequest: any = {
            type: body.types,
            category: body.category,
            content: body.content,
        };
        const state: any = getState();
        const { files } = state.postingReducer;
        const { category, searchType } = state.homeReducer;
        const listFile = files.filter((file: any) => !!file && typeof file !== "string");
        let fileStamp = [];
        fileStamp = await S3FileService.uploadFileToS3(listFile, "post_file_");
        bodyRequest = {
            ...bodyRequest,
            files: fileStamp,
        };
        const { data } = await instance.post<IResponseCreatePost>("/post", bodyRequest);
        dispatch(
            searchPost({ category: category, size: 10, searchType: searchType, page: 1, scanIndexForward: false })
        );
        return data.data;
    }
);


export const fetchToUpdatePost = createAsyncThunk("posting/fetch-update-post", async (postId: string) => {
    const { data } = await instance.get<IResponsePostDetail>("/post/" + postId);
    return data;
});

export const updatePost = createAsyncThunk(
    "posting/update-post",
    async (body: IParametersUpdatePost, { getState, dispatch }) => {
        let params: IPost = {
            ...body,
            id: body.postId,
        };
        const state: any = getState();
        const { files } = state.postingReducer;
        const { category, searchType } = state.homeReducer;
        const newFiles = files.filter((file: any) => typeof file.url !== "string");
        let newfileStamp = await S3FileService.uploadFileToS3(newFiles, "post_file_");
        const oldFiles = files.filter((file: any) => !!file && typeof file.url === "string");
        params.files = [...newfileStamp, ...oldFiles];
        const res = await PostService.update(params);
        dispatch(
            searchPost({ category: category, size: 10, searchType: searchType, page: 1, scanIndexForward: false })
        );
        return res.data;
    }
);

const postingSlide = createSlice({
    name: "posting",
    initialState: initState,
    reducers: {
        addFile: (state, action) => {
            const { file, index } = action.payload;
            state.files.push(file);
            state.files = [...state.files];
        },
        removeFile: (state, action) => {
            state.files = action.payload;
        },
        changeTypePublic: (state, action) => {
            state.publicType = action.payload;
        },
        resetForm: (state) => {
            state.files = initFiles;
            state.title = "";
            state.content = "";
            state.publicType = TYPE_PUBLIC_POSTING.PUBLIC;
            state.category = "";
            state.medias = [];
        },
        updateTitle: (state, action) => {
            state.title = action.payload;
        },
        updateContent: (state, action) => {
            state.content = action.payload;
        },

        updateCategory: (state, action) => {
            state.category = action.payload;
        },

        setIsOpenSub: (state, action) => {
            state.isOpenSub = action.payload;
        },
        calcTotalFilesSize: (state, action) => {
            state.totalFilesSize = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchToUpdatePost.fulfilled, (state, action) => {
            const { data } = action.payload;
            data.files?.forEach((file, index) => {
                state.files[index] = file;
            });
            state.content = data.content.trim();
            state.publicType = data.type;
            state.category = data.category;
            state.files = data.files;
        });
        builder.addMatcher(isPending(createPost, fetchToUpdatePost, updatePost), (state, action) => {
            state.loading = true;
        });
        builder.addMatcher(isFulfilled(createPost, fetchToUpdatePost, updatePost), (state, action) => {
            state.loading = false;
        });
        builder.addMatcher(isRejected(createPost, fetchToUpdatePost, updatePost), (state, action) => {
            state.loading = false;
        });
    },
});

export const {
    removeFile,
    addFile,
    changeTypePublic,
    resetForm,
    updateTitle,
    updateContent,
    updateCategory,
    setIsOpenSub,
    calcTotalFilesSize,
} = postingSlide.actions;
const postingReducer = postingSlide.reducer;
export default postingReducer;
