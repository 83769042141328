import { createAsyncThunk, createSlice, isFulfilled, isPending } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ICustomer, IHistoryRichcapItem } from "types/customer.type";
import { IDataResponseSearchPost } from "types/post.type";
import { IRichcapHistory } from "types/richcap.type";
import instance from "../../config/axios.config";

type IInitState = {
    post: IDataResponseSearchPost;
    isLoading: boolean;
    otherUserInfo: ICustomer | null;
    richcapHistory: IHistoryRichcapItem[];
};

const initPost: IDataResponseSearchPost = {
    data: [],
    page: 0,
    size: 10,
    totalData: 0,
    totalPage: 0,
    message: "",
    result: false,
};

const initialState: IInitState = {
    post: initPost,
    isLoading: false,
    otherUserInfo: null,
    richcapHistory: [],
};

export const searchPostProfile = createAsyncThunk(
    "/customer/post/",
    async (entity: { customerId: string | null; category: string; page: number; size: number; status: number }) => {
        const { customerId, ...body } = entity;
        console.log(customerId, body);
        const { data } = await instance.post<IDataResponseSearchPost>(`/customer/post/${customerId}`, body);
        console.log(data);
        return data;
    }
);

export const loadMorePost = createAsyncThunk("post/load-more-post", async (entity: any) => {
    const { customerId, ...body } = entity;
    console.log(customerId, body);
    const { data } = await instance.post<IDataResponseSearchPost>(`/customer/post/${entity.customerId}`, entity);
    console.log(data);
    return data;
});

export const getOtherUserEntity = createAsyncThunk("otherUser/info", async (params: { otherUserId: string }) => {
    const res = await instance.get(`/customer/get-other-customer/${params.otherUserId}`);
    return res.data;
});

export const followUserEntity = createAsyncThunk(
    "followUser",
    async (entity: { otherUserId?: string; type: number }) => {
        const { data } = await instance.post("/customer/follow", entity);
        return data;
    }
);

export const subscribeUserEntity = createAsyncThunk(
    "subscribeUser",
    async (entity: { otherUserId?: string; type: number }) => {
        const { data } = await instance.post("/customer/subscribe", entity);
        return data;
    }
);

export const getHistoryRichcapEntity = createAsyncThunk("richcap-history/get", async (entity: IRichcapHistory) => {
    const res = await instance.post(`richcap/richcap-price-history/search-by-richcap-id`, entity);
    return res.data;
});

const otherProfileSlice = createSlice({
    name: "otherProfile",
    initialState,
    reducers: {
        updateMyRichcap: (state, action) => {
            state.otherUserInfo = { ...state.otherUserInfo, myRichcap: action.payload } as ICustomer;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(loadMorePost.fulfilled, (state, action) => {
                const { data, page, size, totalData, totalPage, message, result } = action.payload;
                if (data) {
                    state.post = {
                        data: [...state.post.data, ...data],
                        page,
                        size,
                        totalData,
                        totalPage,
                        message,
                        result,
                    };
                }
            })
            .addCase(getHistoryRichcapEntity.fulfilled, (state, action) => {
                const { data } = action.payload;
                state.richcapHistory = data;
            })
            .addMatcher(isPending(followUserEntity, subscribeUserEntity, getOtherUserEntity), (state, action) => {
                state.isLoading = true;
            })
            .addMatcher(isFulfilled(followUserEntity, subscribeUserEntity), (state, action) => {
                state.isLoading = false;
            })

            .addMatcher(isFulfilled(getOtherUserEntity), (state, action) => {
                const { data } = action.payload;
                state.isLoading = false;
                state.otherUserInfo = data;
            });
    },
});

const otherReducer = otherProfileSlice.reducer;
export const { updateMyRichcap } = otherProfileSlice.actions;
export default otherReducer;
