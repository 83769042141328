import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import path from "router/path";
import { getAccessToken } from "utils/helpers/authentication";

const instance = axios.create({
    // baseURL: "ws://192.168.2.147:3001",
    baseURL: process.env.REACT_APP_API_URL,
});
instance.interceptors.request.use((config) => {
    const token = getAccessToken();
    const configAxios = config;
    if (configAxios && configAxios.headers && token) {
        configAxios.headers.Authorization = `Bearer ${token}`;
    }
    return configAxios;
});

instance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
        const status = error.status || (error.response ? error.response.status : 0);
        if (status === 401) {
            toast.error("End of login session.", { toastId: 401 });
            localStorage.clear();
            window.location.href = `#${path.login}`;
        } else {
            const errorMessage: any = error.response?.data;
            toast.error(errorMessage.message);
            return error.response;
        }
        return Promise.reject(error);
    }
);

// UPLOAD FILE MEDIA TO S3
const BaseUploadMediaToS3 = axios.create();
BaseUploadMediaToS3.interceptors.request.use((config: any) => {
    if (!config.headers) {
        config.headers = {};
    }
    config.headers["Content-Type"] = "application/octet-stream; charset=binary";
    return config;
});
export { BaseUploadMediaToS3 };

export const rawInstance = axios.create();

export default instance;
