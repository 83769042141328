import { IAuth, IAuthResponse } from "types/auth.type";
import { createServiceNoToken } from "./axios";

const instance = createServiceNoToken(process.env.REACT_APP_API_URL);

const getAuthInfo = async (params: IAuth): Promise<IAuthResponse> => {
  const result = await instance.post("/customer/login-sso", params);
  return result.data;
};

export { getAuthInfo };
