import {
  createAsyncThunk,
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from "@reduxjs/toolkit";
import instance from "../../config/axios.config";
import {
  Comment,
  Comments,
} from "../../components/ViewPost/Comment/comment.const";
import { IResponsePostComment } from "../../components/ViewPost/Comment/comment.const";
import {
  IPostDetail,
  IResponseComment,
  IResponseCreateComment,
  IResponseRichCap,
  IRichCap,
} from "types/post.type";
import { IResponsePostDetail } from "../../types/post.type";
import { ICustomer } from "../../types/customer.type";
import { searchPost } from "page/Home/home.reducer";
import { IComment } from '../../types/comment.type';


const initResComment: IResponseComment = {
  result: true,
  data: [],
  size: 10,
  totalPage: 0,
  page: 1,
  message: '',
  totalData: 0,
};
const initState = {
  loading: false,
  postData: {} as IPostDetail,
  size: 10,
  totalPage: 0,
  page: 1,
  totalData: 0,
  responComment: initResComment,
  commentTotal: [] as IComment [],
  firstLevelComment: [] as IComment [],
  secondLevelComment: [] as IComment [],
  threeLevelComment: [] as IComment [],
  richcap: null as IRichCap | null,
  countComment: 0,
};

export const getDetailPost = createAsyncThunk(
  "post-detail/get-detail-post",
  async (postId: string) => {
    const { data } = await instance.get<IResponsePostDetail>("/post/" + postId);
    return data;
  }
);
export const deletePost = createAsyncThunk(
  "post-detail/delete-post",
  async (postId: string) => {
    const { data } = await instance.delete<IResponsePostDetail>("/post/delete/" + postId);
    return data;
  }
);

export const getComments = createAsyncThunk(
  "post-detail/comments",
  async (entity: { postId: string; userId?: string; parentId?: string; page?: number; size?: number; isLoadMore?: boolean;  }) => {
    let body = {
      postId: entity.postId,
      userId: entity.userId,
      parentId: entity.parentId,
      status: 1,
      page: entity.page,
      size: entity.size,
      scanIndexForward: false
    };
    if (entity.isLoadMore) {
      body = {
        ...body,
      };
    }
    const { data } = await instance.post<IResponseComment>(
      "/comment/search",
      body
    );
    return data;
  }
);

export const loadMoreComment = createAsyncThunk(
  "post-detail/comments",
  async (entity: { postId: string; userId?: string; parentId?: string; page?: number; size?: number; isLoadMore?: boolean;  }) => {
    let body = {
      postId: entity.postId,
      userId: entity.userId,
      parentId: entity.parentId,
      status: 1,
      page: entity.page,
      size: entity.size,
      scanIndexForward: false
    };
    if (entity.isLoadMore) {
      body = {
        ...body,
      };
    }
    const { data } = await instance.post<IResponseComment>(
      "/comment/search",
      body
    );
    return data;
  }
);

export const createComment = createAsyncThunk(
  "post-detail/create-comment",
  async (body: any) => {
    const { data } = await instance.post<IResponseCreateComment>(
      "/comment",
      body
    );
    return data;
  }
);
export const deleteComment = createAsyncThunk(
  "post-detail/delete-comment",
  async (commentId: string) => {
    const { data } = await instance.delete<string>("/comment/delete/" + commentId);
    return commentId;
  }
);
export const updateComment = createAsyncThunk(
  "post-detail/update-comment",
  async (entity: { body: any; commentId: string }) => {
    const { data } = await instance.put<any>(`/comment/${entity.commentId}`, entity.body);
    return data.data;
  }
);
export const likePost = createAsyncThunk(
  "post-detail/like-post",
  async (postId: string) => {
    const { data } = await instance.post<any>("/like", { postId });
    return data;
  }
);



export const getRichCap = createAsyncThunk(
  "view-post/richcap",
  async (richcapId: string) => {
    const { data } = await instance.get<IResponseRichCap>(
      `richcap/${richcapId}`
    );
    return { data };
  }
);

const postDetailSlide = createSlice({
  name: "post",
  initialState: initState,
  reducers: {
  },
  extraReducers(builder) {
    builder.addCase(getDetailPost.fulfilled, (state, action) => {
      state.postData = action.payload.data;
      state.countComment = action.payload.data.numberComment;
    });
    builder.addCase(createComment.fulfilled, (state, action) => {
        const newCmt = action.payload.data;
        state.commentTotal.unshift(newCmt);
        state.firstLevelComment = state.commentTotal.filter(e => e.level === 1);
        state.secondLevelComment = state.commentTotal.filter(e => e.level === 2);
        state.threeLevelComment = state.commentTotal.filter(e => e.level === 3);
        if (action.payload.result === true) {
          state.countComment = state.countComment + 1;
        }
    });
    builder.addMatcher(isFulfilled(deleteComment), (state, action) => {
      const idComment = action.payload;
      const removeComments = (comments: IComment[], commentId: string) => {
        let i = 0;
        while (i < comments.length) {
          const comment = comments[i];
          if (comment.parentId === commentId) {
            comments.splice(i, 1);
            removeComments(comments, comment.id);
          } else {
            i++;
          }
        }
      }
      removeComments(state.commentTotal, idComment);
      state.commentTotal = state.commentTotal.filter(e => (e.id !== idComment));
      state.firstLevelComment = state.commentTotal.filter(e => e.level === 1);
      state.secondLevelComment = state.commentTotal.filter(e => e.level === 2);
      state.threeLevelComment = state.commentTotal.filter(e => e.level === 3);
      state.countComment = state.commentTotal.length;
    });
    builder.addMatcher(
      isPending(getDetailPost, createComment, deletePost),
      (state) => {
        state.loading = true;
      }
    );
    builder.addMatcher(
      isRejected(getDetailPost, createComment, deletePost),
      (state) => {
        state.loading = false;
      }
    );
    builder.addMatcher(
      isFulfilled(getDetailPost, createComment, deletePost),
      (state) => {
        state.loading = false;
      }
    );
    builder.addMatcher(isFulfilled(getComments), (state, action) => {
      state.responComment = action.payload;
      state.commentTotal = state.responComment.data;
      state.firstLevelComment = state.commentTotal.filter(e => e.level === 1);
      state.secondLevelComment = state.commentTotal.filter(e => e.level === 2);
      state.threeLevelComment = state.commentTotal.filter(e => e.level === 3);
    });
    builder.addMatcher(isFulfilled(loadMoreComment), (state, action) => {
      const { data, result, size, totalPage, page, message, totalData } = action.payload;
      if (data) {
        state.responComment = {
          data: [...state.responComment.data, ...data],
          result,
          size,
          totalPage,
          page,
          message,
          totalData
        };
      }
    });
    builder.addMatcher(isFulfilled(getRichCap), (state, action) => {
      state.richcap = action.payload.data.data;
    });
  },
});

const postDetailReducer = postDetailSlide.reducer;
export default postDetailReducer;
