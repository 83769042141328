
import { IPost } from "types/post.type";
import { createService } from "./axios";
import { ResultResponse } from "types/response.type";


const instance = createService(process.env.REACT_APP_API_URL);


const update = async (data: IPost): Promise<ResultResponse<any>> => {
  const result = await instance.put('/post/' + data.id, data);
  return result.data;
};
export {
  update
};
