import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instance, { BaseUploadMediaToS3 } from "config/axios.config";
import { ICustomerState, IEditProfileCustomer, IParamsRichcapCustomer } from "types/customer.type";
import { IDataResponseSearchPost, IDataSearchPost, IPostDetail, IPostItem } from "types/post.type";
import { convertFileToArrayBuffer, LOCAL_STORAGE } from "../../utils/contants";
import Buffer from "buffer";
import { toast } from "react-toastify";
import { IRichcapHistory } from "types/richcap.type";

const initPost: IDataResponseSearchPost = {
    result: false,
    message: "",

    page: 1,
    size: 10,
    totalPage: 0,
    data: [] as IPostDetail[],
    totalData: 0,
};

const initialState: ICustomerState = {
    post: initPost,
    isLoading: false,
    customerInfo: null,
    richcapHistory: [],
    richcaps: [],
};

export const searchPostProfile = createAsyncThunk(
    "/customer/post/",
    async (entity: { customerId: string | null; category: string; page: number; size: number }) => {
        const { data } = await instance.post<IDataResponseSearchPost>(`/customer/post/${entity.customerId}`, entity);
        return data;
    }
);

export const loadMorePost = createAsyncThunk("post/load-more-post", async (entity: any) => {
    const { data } = await instance.post<IDataResponseSearchPost>(`/customer/post/${entity.customerId}`, entity);
    return data;
});

export const getCustomerEntity = createAsyncThunk("customer/get", async (entity: { customerId: string }) => {
    const res = await instance.get(`customer/get-customer/${entity.customerId}`);
    return res.data;
});

export const getHistoryRichcapEntity = createAsyncThunk("richcap-history/get", async (entity: IRichcapHistory) => {
    const res = await instance.post(`richcap/richcap-price-history/search-by-richcap-id`, entity);
    return res.data;
});

export const getRichcapsCustomerEntity = createAsyncThunk(
    "customer/richcap",
    async (entity: IParamsRichcapCustomer) => {
        const res = await instance.post(`customer/search-richcap`, entity);
        return res.data;
    }
);

const getLinkUploadToS3 = (fileName: string) => {
    const params = {
        fileKeys: [fileName],
    };
    return instance({
        url: "/file/create-presigned-url-upload-file",
        method: "POST",
        data: params,
    });
};

export const editProfile = createAsyncThunk(
    "customer/edit-profile",
    async (entity: IEditProfileCustomer, { dispatch }) => {
        let body: any = {
            name: entity.name,
            gender: entity.gender,
            phone: entity.phone,
            birthday: Number(entity.birthday),
        };

        if (entity.avatar) {
            const filename = new Date().getTime().toString() + "_" + entity.avatar?.name;
            const linkUpload = await getLinkUploadToS3(filename);
            const arrayBuffer = await convertFileToArrayBuffer(entity.avatar);
            const buffer = Buffer.Buffer.from(arrayBuffer);
            await BaseUploadMediaToS3.put(linkUpload.data[0].urlUpload, buffer);
            const file: any = {
                filename: filename,
                contentType: entity.avatar?.type,
                url: `${process.env.REACT_APP_API_URL}/download/` + filename,
                fieldname: "avatar",
                description: "avatar",
            };
            body = {
                ...body,
                avatar: file,
            };
        }
        const customerId = localStorage.getItem(LOCAL_STORAGE.CUSTOMER_ID) as string;
        const res = await instance.put(`customer/${customerId}`, body);
        if (res.status === 200) {
            dispatch(getCustomerEntity({ customerId }));
        }
        return res.data;
    }
);

const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(searchPostProfile.fulfilled, (state, action) => {
                const { data, page, size, totalData, totalPage, message, result } = action.payload;

                if (data) {
                    state.post = {
                        data: [...data],
                        page,
                        size,
                        totalData,
                        totalPage,
                        message,
                        result,
                    };
                }
            })
            .addCase(loadMorePost.fulfilled, (state, action) => {
                const { data, page, size, totalData, totalPage, message, result } = action.payload;

                if (data) {
                    state.post = {
                        data: [...state.post.data, ...data],
                        page,
                        size,
                        totalData,
                        totalPage,
                        message,
                        result,
                    };
                }
            })
            .addCase(getRichcapsCustomerEntity.fulfilled, (state, action) => {
                const { data } = action.payload;
                state.richcaps = data;
            })
            .addCase(getCustomerEntity.fulfilled, (state, action) => {
                const { data } = action.payload;
                state.customerInfo = data;
            })
            .addCase(getHistoryRichcapEntity.fulfilled, (state, action) => {
                const { data } = action.payload;
                state.richcapHistory = data;
            })
            .addCase(editProfile.fulfilled, (state, action) => {
                const { data } = action.payload;
            });
    },
});

const profileReducer = profileSlice.reducer;
export default profileReducer;
