const Loading = () => {
  return (
    <div className="h-screen flex justify-center items-center">
      <div className="mr-2">
        <i className="bx bx-loader-alt bx-spin text-textBase text-[16px]" />
      </div>
    </div>
  );
};

export default Loading;
