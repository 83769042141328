const path = {
  home: "/",
  posting: "/post",
  edit_post: "/post/update/:postId",
  post_detail: "/post/:postId",
  login: "/login",
  code: "/code",
  profile: "/profile",
  register: "/register",
  transaction: "/transaction/:otherId",
  customerProfile: "/customer-profile/:id",
  order_confirmation: "/order-confirmation",
  search: "/search",
  notification: "/notification",
  richcapConfig: "/richcap-config",
  verify_property: "/verify-property",
  editProfile: "/profile/edit",
  rechargeProfile: "/profile/recharge",
};

export default path;
