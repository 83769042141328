import {
    createAsyncThunk,
    createSlice,
} from "@reduxjs/toolkit";
import instance, {BaseUploadMediaToS3} from "../../config/axios.config";
import {IParametersVerifyProperty, IResponseVerifyProperty} from "./verify_property.type";
import {convertFileToArrayBuffer} from "../../utils/contants";
import Buffer from "buffer";
import { S3FileService } from "services";

interface InitStateModel {
    loading: boolean;
    mediaFiles: any[];
    statusVerify: null | number;
    totalFilesSize: number;
}

const initState: InitStateModel = {
    loading: false,
    mediaFiles: [],
    statusVerify: null,
    totalFilesSize: 0,
};

const getLinkUploadToS3 = (fileName: string) => {
    const params = {
        fileKeys: [fileName]
    };
    return instance({
        url: "/file/create-presigned-url-upload-file",
        method: "POST",
        data: params
    });
}


export const verifyProperty = createAsyncThunk(
    "property/verify-property",
    async (entity: IParametersVerifyProperty) => {
        let fileStamp = [];
        // for (let i = 0; i < entity.files.length; i++) {
        //     if (entity.files[i]) {
        //         const filename = new Date().getTime().toString() + "_" + entity.files[i]?.name;
        //         const linkUpload = await getLinkUploadToS3(filename);
        //         const arrayBuffer = await convertFileToArrayBuffer(entity.files[i]);
        //         const buffer = Buffer.Buffer.from(arrayBuffer);
        //         await BaseUploadMediaToS3.put(linkUpload.data[0].urlUpload, buffer);
        //         const file: any = {
        //             filename: filename,
        //             contentType: entity.files[i]?.type.split("/").shift(),
        //             url: `${process.env.REACT_APP_API_URL}/download/` + filename,
        //             fieldname: entity.files[i]?.type,
        //         };
        //         files.push(file);
        //     }
        // }
        fileStamp = await S3FileService.uploadFileToS3(entity.files, "post_file_");

        const requestBody = {
            ...entity,
            files: fileStamp,
        }

        const {data} = await instance.post<IResponseVerifyProperty>("/customer/property/verify-property", requestBody);
        console.log("datassss", data);
        
        return data;
    }
);

const propertySlide = createSlice({
    name: "property",
    initialState: initState,
    reducers: {
        addFile: (state, action) => {
            const {file, index} = action.payload;
            if (index === state.mediaFiles.length - 1) {
                state.mediaFiles.push(null);
            }
            state.mediaFiles[index] = file;
            state.mediaFiles = [...state.mediaFiles];
            console.log(state.mediaFiles);
        },
        removeFile: (state, action) => {
            const fileIndex = action.payload;
            const listClone = [...state.mediaFiles];
            listClone.splice(fileIndex, 1);
            state.mediaFiles = listClone;
        },
        resetForm: (state) => {
            state.mediaFiles = [];
        },
        setStatusVerify: (state, action) => {
            state.statusVerify = action.payload;
        },
        calcTotalFilesSize: (state, action) => {
            state.totalFilesSize = action.payload;
        },
    },
});

export const {
    removeFile,
    addFile,
    resetForm,
    setStatusVerify,
    calcTotalFilesSize
} = propertySlide.actions;
const propertyReducer = propertySlide.reducer;
export default propertyReducer;
