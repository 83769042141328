import "boxicons/css/boxicons.min.css";
import { Suspense, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import path from "router/path";
import { getAccessToken, getCustomerId } from "utils/helpers/authentication";
import "./App.scss";
import "./i18n";
import { resetFocus } from "./page/Home/home.reducer";
import Router from "./router";
import { useAppDispatch } from "./store/hooks";
import Loading from "./utils/Loading";
// Import Swiper styles
import { getCustomerEntity } from "page/Profile/profile.reducer";
import "swiper/swiper-bundle.css";
import baseTheme, { THEME } from "themes/base";
import darkTheme from "themes/dark";
import { applyTheme } from "themes/utils";
import { LOCAL_STORAGE } from "utils/contants";
function App() {
  const theme = localStorage.getItem(LOCAL_STORAGE.THEME);
  const customerId = getCustomerId();
  const token = getAccessToken();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleChangeFocus = () => {
    dispatch(resetFocus());
  };

  const fetchCustomer = () => {
    if (customerId) {
      const params = {
        customerId,
      };

      dispatch(getCustomerEntity(params));
    }
  };

  useEffect(() => {
    fetchCustomer();
  }, [customerId]);

  useEffect(() => {
    if (!token) {
      navigate(path.login);
    }
  }, [navigate, token]);

  useEffect(() => {
    if (theme === THEME.LIGHT) {
      applyTheme(baseTheme);
      localStorage.setItem(LOCAL_STORAGE.THEME, THEME.LIGHT);
    } else {
      applyTheme(darkTheme);
      localStorage.setItem(LOCAL_STORAGE.THEME, THEME.DARK);
    }
  }, [theme]);

  return (
    <div className="App bg-primary" onClick={handleChangeFocus}>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} theme="colored" autoClose={2000} />
      <Suspense fallback={<Loading />}>
        <Router />
      </Suspense>
    </div>
  );
}

export default App;
