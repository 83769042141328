import {
  createAsyncThunk,
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import instance from "config/axios.config";
import { toast } from "react-toastify";
import { RootState } from "store/store";
import { IParamsRichcapCustomer, IRichcap } from "types/customer.type";
import { IFormResponseGetCode, IFormResponseLogin } from "types/login.type";
import { IRichcapHistory } from "types/richcap.type";
import {
  ICreateTrace,
  ISearchTrace,
  ITrace,
  ITraceHistory,
  IUpdateTrace,
} from "types/transaction.type";
import { OTP_TOKEN, PHONE_NUMBER } from "utils/contants";

interface TransactionState {
  isLoading: boolean;
  bodyCreateTrace: ICreateTrace;
  traces: ITrace[];
  richcaps: IRichcap[];
  richcapHistory: IRichcapHistory[];
}

const initialState: TransactionState = {
  isLoading: false,
  bodyCreateTrace: {} as ICreateTrace,
  traces: [],
  richcaps: [],
  richcapHistory: [],
};

export const createTraceThunk = createAsyncThunk("createTrace", async (body: ICreateTrace) => {
  const { data } = await instance.post("/trace", body);
  return data;
});

export const cancelOrderThunk = createAsyncThunk("cancelOrder", async (otherId: string) => {
  const res = await instance.post("/trace/cancel", { otherId });
  return res;
});

export const editCommandThunk = createAsyncThunk("editCommand", async (params: any) => {
  const res = await instance.put(`/trace/${params.otherId}`, params.body);
  return res;
});

export const searchTraceThunk = createAsyncThunk("searchTrace", async (body: ISearchTrace) => {
  const res = await instance.post(`/trace/search`, body);
  return res;
});

export const sendCodeThunk = createAsyncThunk(
  "login/handleSendCode",
  async (data: { phone: string; countryCode: string }) => {
    const result = await instance.post<IFormResponseGetCode>("/customer/login/create-otp", data);
    return result.data;
  }
);

export const verifyCodeThunk = createAsyncThunk(
  "login/verifyCode",
  async (data: Omit<IFormResponseGetCode, "erorCodeSendSmsOTP">, { dispatch }) => {
    const result = await instance.post<IFormResponseLogin>("/customer/login/verify-otp", data);
    const body = localStorage.getItem("create_trace_body")
      ? JSON.parse(localStorage.getItem("create_trace_body") as string)
      : {};
    dispatch(createTraceThunk(body));
    return result.data;
  }
);

export const createTraceEntity = createAsyncThunk(
  "trace/create",
  async (data: ICreateTrace, { dispatch }) => {
    const result = await instance.post<ICreateTrace>("trace/create", data);
    return result.data;
  }
);

export const cancelTraceEntity = createAsyncThunk(
  "trace/cancel/",
  async (entity: ITraceHistory, { dispatch }) => {
    console.log("entity", entity);

    const result = await instance.delete(`/trace/cancel/${entity.id}`);
    return result.data;
  }
);

export const getTracesHistoryEntity = createAsyncThunk(
  "trace/get",
  async (entity: { customerId?: string; scanIndexForward: boolean }, { dispatch }) => {
    const res = await instance.post(`trace/search-traces-by-customer-id`, entity);
    return res.data;
  }
);

export const getRichcapsEntity = createAsyncThunk(
  "customer/richcap",
  async (entity: { keyCodeSearch?: string | null; isVerifyKYC: boolean }) => {
    const res = await instance.post(`richcap/search`, entity);
    return res.data;
  }
);

export const getHistoryRichcapEntity = createAsyncThunk(
  "richcap-history/get",
  async (entity: IRichcapHistory) => {
    const res = await instance.post(`richcap/richcap-price-history/search-by-richcap-id`, entity);
    return res.data;
  }
);

export const getTenTransactionFromNow = createAsyncThunk(
  "richcap-history/get",
  async (entity: IRichcapHistory) => {
    const res = await instance.post(`richcap/richcap-price-history/search-by-richcap-id`, entity);
    return res.data;
  }
);

const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendCodeThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        localStorage.setItem(PHONE_NUMBER, action.payload.phone);
        localStorage.setItem(OTP_TOKEN, action.payload.otpToken);
      })
      .addCase(createTraceThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        localStorage.setItem("create_trace_body", "");
        toast.success(action.payload.message);
      })
      .addCase(getRichcapsEntity.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.richcaps = data;
      })
      .addMatcher(isPending(createTraceEntity, cancelTraceEntity), (state, action) => {
        state.isLoading = true;
      })
      .addMatcher(isFulfilled(createTraceEntity, cancelTraceEntity), (state, action) => {
        state.isLoading = false;
      })
      .addMatcher(isPending(sendCodeThunk, verifyCodeThunk, getTracesHistoryEntity), (state) => {
        state.isLoading = true;
      })
      .addMatcher(isFulfilled(getTracesHistoryEntity), (state, action) => {
        const { data } = action.payload;
        state.traces = data;
      })
      .addMatcher(isFulfilled(getHistoryRichcapEntity), (state, action) => {
        const { data } = action.payload;
        state.richcapHistory = data;
      })
      .addMatcher(isRejected(sendCodeThunk, verifyCodeThunk, getTracesHistoryEntity), (state) => {
        state.isLoading = false;
      });
  },
});

const transactionReducer = transactionSlice.reducer;
export default transactionReducer;
