import { createTheme } from "./utils";
const darkTheme = createTheme({
    primary: "#12151B",
    secondary: "#242B38",
    textBase: "#FFFFFF",
    thirdColor: "#000000",
    colorADAFC4: "#ADAFC4",
    color2AB7F1: "#2AB7F1",
    colorBgHashTag: "#242B38",
    colorTextHashTag: "#07EFEB",
    color2F49CD: "#2F49CD",
    textDesPost: "#FFFFFF",
    colorBgFocusHashTag: "#232A37",
    textValue: "#FFFFFF",
    textCategory: "#FFFFFF",
    colorModal: "#242B38",
    bgRead: "#000000",
    bgNoti: "#242B38",
    mainNoti: "#000000",

    //color modal
    textClose: "#ADAFC4",
    bgIconModal: "#12151B",
    textTypePosting: "#2AB7F1",
    textDisable: "#5E6272",
    colorBgBtnDisable: "#242B38",
    lineTab: "#242B38",
    bgItemStatus: "#373F54"
});
export default darkTheme;
