import React from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import { getAccessToken } from "utils/helpers/authentication";
import path from "./path";

const Home = React.lazy(() => import("../page/Home/Home"));
const ViewPost = React.lazy(() => import("../page/ViewPost/ViewPost"));
const Posting = React.lazy(() => import("../page/Posting/Posting"));
const Login = React.lazy(() => import("../page/Login"));
const Code = React.lazy(() => import("../page/Code/Code"));
const Profile = React.lazy(() => import("../page/Profile/Profile"));
const Transaction = React.lazy(() => import("../page/Transaction/Transaction"));
const CustomerProfile = React.lazy(() => import("../page/OtherProfile"));
const OrderConfirmation = React.lazy(() => import("../page/PhoneNumber"));
const Search = React.lazy(() => import("../page/Search/Search"));
const Notification = React.lazy(() => import("../page/Notification"));
const VerifyProperty = React.lazy(() => import("../page/VerifyProperty/VerifyProperty"));
const RichCapConfig = React.lazy(() => import("page/RichCap/RichCapConfig"));
const EditProfile = React.lazy(() => import("page/EditProfile"));
const RechargeSetUpPage = React.lazy(() => import("page/Profile/recharge-setup/RechargeSetUpPage"));

const Router = () => {
  const location = useLocation();

  return useRoutes([
    { element: <Notification />, path: path.notification },
    { element: <Search />, path: path.search },
    { element: <Posting />, path: path.posting },
    { element: <Posting />, path: path.edit_post },
    { element: <ViewPost />, path: path.post_detail },
    { element: <Home />, path: path.home },
    { element: <Code />, path: path.code },
    { element: <Profile />, path: path.profile },
    { element: <Transaction />, path: path.transaction },
    { element: <CustomerProfile />, path: path.customerProfile },
    { element: <OrderConfirmation />, path: path.order_confirmation },
    { element: <VerifyProperty />, path: path.verify_property },
    { element: <RichCapConfig />, path: path.richcapConfig },
    { element: <EditProfile />, path: path.editProfile },
    { element: <RechargeSetUpPage />, path: path.rechargeProfile },
    {
      element: getAccessToken() ? (
        <Navigate to={path.home} state={{ from: location }} />
      ) : (
        <Login />
      ),
      path: path.login,
    },
  ]);
};

export default Router;
