import { createAsyncThunk, createSlice, isPending, isRejected } from "@reduxjs/toolkit";
import instance from "config/axios.config";
import { ICreateRichCap, IRichCapState } from "types/richcap.type";

const initialState: IRichCapState = {
  isLoading: false,
};

const richcapCreateUrl = "/richcap";

export const createRichCapEntity = createAsyncThunk(
  "richcap/create",
  async (enity: ICreateRichCap) => {
    const res = await instance.post<ICreateRichCap>(richcapCreateUrl, enity);
    return res.data;
  }
);

const richcapSlice = createSlice({
  name: "richcap",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createRichCapEntity.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addMatcher(isPending(createRichCapEntity), (state) => {
      state.isLoading = true;
    });
  },
});

const richcapReducer = richcapSlice.reducer;
export default richcapReducer;
