import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instance from "../../config/axios.config";
import { LOCAL_STORAGE, NOTIFICATION } from "../../utils/contants";
import queryString from "query-string";

const notificationSlice = createSlice({
    name: "notification",
    initialState: {
        isLoading: false,
        isDetailOpen: false,
        activeTab: NOTIFICATION.ALL,
        dataNoti: [],
    },
    reducers: {
        setActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(getNotificationThunk.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getNotificationThunk.fulfilled, (state, action) => {
            state.isLoading = false;
            state.dataNoti = action.payload;
        });
    },
});

export const getNotificationThunk = createAsyncThunk("getNotification", async (data: any) => {
    const paramsQuery = queryString.stringify(data.params);
    const response = await instance.get(`/customer/filter-notification?${paramsQuery}`, data.data);
    console.log(response.data);

    return response.data.data;
});

const notificationReducer = notificationSlice.reducer;
export const { setActiveTab } = notificationSlice.actions;
export default notificationReducer;
