import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import instance, { rawInstance } from "../../config/axios.config";
import {
    IDataResponseSearchPost,
    IHastag,
    IResponseBestPosting,
    IResponseHastag,
    IResponseSearchPost,
    IResponseTopValue,
    IBestPosting,
    IPostItem,
    ITopValueItem,
    IDataSearchPost,
    IResponTopValue,
} from "../../types/home.type";
import { LOCAL_STORAGE, HOMETAB } from "../../utils/contants";
import { ICreateKYCRequest, IResponKYC, ISessionKYC } from "../../types/kyc.type";
import { toast } from "react-toastify";
import { ICustomer } from "types/customer.type";
import { getCustomerId } from "../../utils/helpers/authentication";
import queryString from "query-string";

const initSearchPost: IDataResponseSearchPost = {
    data: {} as any,
    message: "",
};

const initPost: IDataSearchPost = {
    result: true,
    data: [],
    size: 10,
    totalPage: 0,
    page: 1,
    message: "",
    totalData: 0
};

const initState = {
    initSearchPost: initSearchPost,
    isFocus: {} as any,
    dataSearchPost: initPost,
    loading: false,
    bestPosting: {} as IPostItem,
    hastags: {} as IResponseHastag,
    topValue: [] as ITopValueItem[],
    category: "",
    searchType: 1,
    activeTab: HOMETAB.FORYOU,
    customer: {} as ICustomer,
    realTimeNotiQuantity: null,
    lastNotiQuantity: null,
};

export const searchPost = createAsyncThunk("post/search-post", async (body: any) => {
    const { data } = await instance.post<IDataSearchPost>("/post/search", body);
    return data;
});

const getNotiQuantity = async () => {
    const paramsQuery = queryString.stringify({
        customerId: localStorage.getItem("customerId"),
        page: "1",
        size: "10",
    });
    const response = await instance.get(`/customer/filter-notification?${paramsQuery}`, {});
    return response.data;
};

export const getLastNotiQuantity = createAsyncThunk("post/get-last-noti-quantity", getNotiQuantity);
export const getRealtimeNotiQuantity = createAsyncThunk("post/get-realtime-noti-quantity", getNotiQuantity);

export const loadMorePost = createAsyncThunk("post/load-more-post", async (body: any) => {
    const { data } = await instance.post<IDataSearchPost>("/post/search", body);
    console.log(data);
    return data;
});

export const getBestPosting = createAsyncThunk("post/postBestFollower", async () => {
    const { data } = await instance.post<IResponseBestPosting>("/post/postBestFollower");
    return data.data;
});

export const getHastags = createAsyncThunk("post/hastag", async (size: number) => {
    const { data } = await instance.post<IResponseHastag>("/hashtag", { size });
    return data.data;
});

export const getTopValue = createAsyncThunk("customer/topvalue", async () => {
    const { data } = await instance.get<IResponTopValue>("/customer/topvalue", {});
    return data.data;
});

export const getProfileCustomer = createAsyncThunk("customer/get", async (customerId: string) => {
    const res = await instance.get(`/customer/get-customer/${customerId}`);
    console.log(res.data);
    return res.data;
});

export const getSessionKYC = createAsyncThunk("kyc/session", async () => {
    const data = await instance.get<IResponKYC>("/customer/get-session-key");
    if (data.status === 400) {
        if (data.data.message === "verify.kyc.customer") {
            toast.error("Customers are waiting for approval kyc");
        }
        return;
    }
    return data;
});

export const createKycRequest = createAsyncThunk(
    "kyc-request/create",
    async (param: ICreateKYCRequest, { dispatch }) => {
        const { data, status } = await instance.post("/customer/kyc/create", param);

        if (status === 200) {
            const customerId = getCustomerId();

            dispatch(getProfileCustomer(customerId as string));
        }

        return data.data;
    }
);

const homeSlide = createSlice({
    name: "home",
    initialState: initState,
    reducers: {
        setFocus: (state, actions) => {
            state.isFocus[actions.payload] = true;
        },
        setFalseFocus: (state, actions) => {
            state.isFocus[actions.payload] = false;
        },
        resetFocus: (state) => {
            state.isFocus = {};
        },
        setCategory: (state, actions) => {
            state.category = actions.payload;
        },
        setSearchType: (state, actions) => {
            state.searchType = actions.payload;
        },
        setActiveTab: (state, actions) => {
            state.activeTab = actions.payload;
        },
        setRealtimeNotiQuantity: (state, actions) => {
            state.realTimeNotiQuantity = actions.payload;
        },
        setLastNotiQuantity: (state, actions) => {
            state.lastNotiQuantity = actions.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(getLastNotiQuantity.fulfilled, (state, action) => {
            state.lastNotiQuantity = action.payload.totalData;
        });

        builder.addCase(getRealtimeNotiQuantity.fulfilled, (state, action) => {
            state.realTimeNotiQuantity = action.payload.totalData;
        });

        builder.addCase(searchPost.fulfilled, (state, action) => {
            state.dataSearchPost = action.payload;
        });
        builder.addCase(getBestPosting.fulfilled, (state, action) => {
            state.bestPosting = action.payload;
        });
        builder.addCase(getHastags.fulfilled, (state, action) => {
            state.hastags.data = action.payload;
        });
        builder.addCase(getTopValue.fulfilled, (state, action) => {
            state.topValue = action.payload;
        });
        builder.addCase(loadMorePost.fulfilled, (state, action) => {
            const { data, result, size, totalPage, page, message, totalData } = action.payload;
            if (data) {
                state.dataSearchPost = {
                    data: [...state.dataSearchPost.data, ...data],
                    result,
                    size,
                    totalPage,
                    page,
                    message,
                    totalData
                };
            }
        });
        builder.addCase(getProfileCustomer.fulfilled, (state, action) => {
            const { data } = action.payload;
            state.customer = data;
        });

        builder.addMatcher(
            isFulfilled(searchPost, loadMorePost, getBestPosting, getHastags, getTopValue, getProfileCustomer),
            (state) => {
                state.loading = false;
            }
        );
        builder.addMatcher(
            isPending(searchPost, loadMorePost, getBestPosting, getHastags, getTopValue, getProfileCustomer),
            (state) => {
                state.loading = true;
            }
        );
        builder.addMatcher(
            isRejected(searchPost, loadMorePost, getBestPosting, getHastags, getTopValue, getProfileCustomer),
            (state) => {
                state.loading = false;
            }
        );
        builder.addMatcher(isFulfilled(getSessionKYC), (state, action) => {
            if (action.payload !== undefined) {
                const session: string = action.payload?.data?.data?.session;
                const userId: string = action.payload?.data?.data?.userId;
                const url = action.payload?.data?.data?.responseUrl;
                const urlVerify = `${process.env.REACT_APP_RICHCLE_KYC_WEB_URL}/?sessionKey=${session}&userId=${userId}&fromWhere=${process.env.REACT_APP_REDIRECT_URL}`;
                window.open(urlVerify);
            }
        });
        builder.addMatcher(isRejected(createKycRequest), (state, action) => {
            toast.error("You verified KYC Failed");
        });
        builder.addMatcher(isFulfilled(createKycRequest), () => {
            toast.success("Your KYC verification  is in progress");
        });
    },
});

const homeReducer = homeSlide.reducer;
export const {
    setFocus,
    resetFocus,
    setFalseFocus,
    setCategory,
    setSearchType,
    setActiveTab,
    setLastNotiQuantity,
    setRealtimeNotiQuantity,
} = homeSlide.actions;
export default homeReducer;
