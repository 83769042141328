import { createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { IFormResponseLogin } from "types/login.type";
import instance from "../../config/axios.config";
import { LOCAL_STORAGE } from "../../utils/contants";
import { IAuth } from "types/auth.type";
import { AuthService, LocalStorageService } from "services";

const loginSlice = createSlice({
  name: "login",
  initialState: {
    isLoading: false,
    themeLocal: localStorage.getItem('theme'),
  },
  reducers: {
    setThemeLocal: (state, actions) => {
      state.themeLocal = actions.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(logInThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(logInThunk.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(logInThunk.fulfilled, (state, action) => {
      state.isLoading = false;
      localStorage.setItem(LOCAL_STORAGE.ACCESS_TOKEN, action.payload.token);
      localStorage.setItem(
        LOCAL_STORAGE.CUSTOMER_ID,
        action.payload.customerId
      );

      LocalStorageService.set(
        LocalStorageService.OAUTH_TOKEN,
        action.payload.token
      );
      LocalStorageService.set(
        LocalStorageService.USER_ID,
        action.payload.customerId
      );
      LocalStorageService.set(
        LocalStorageService.USER_PROFILE,
        action.payload.customer
      );
    });
  },
});

export const logInThunk = createAsyncThunk(
  "logIn",
  async (req: any) => {
    const params: IAuth = {
      email: req.data.email,
      uid: req.data.userId,
      appid: "SNS",
      sessionKey: {
        ip: req.data.sessionKey.ip,
        key: req.data.sessionKey.key,
      },
    }
    const response = await AuthService.getAuthInfo(params);
    // const { data } = await instance.post<IFormResponseLogin>("/customer/login-sso", {
    //   email: accountInfo.data.email,
    //   uid: accountInfo.data.userId,
    //   appid: "SNS",
    //   sessionKey: {
    //     ip: accountInfo.data.sessionKey.ip,
    //     key: accountInfo.data.sessionKey.key,
    //   },
    // });
    return response;
  }
);

const loginReducer = loginSlice.reducer;
export const {setThemeLocal} = loginSlice.actions;
export default loginReducer;
