import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import instance, { rawInstance } from "../../config/axios.config";
import {
    IDataResponseSearchPost,
    IHastag,
    IResponseBestPosting,
    IResponseHastag,
    IResponseSearchPost,
    IResponseTopValue,
    IBestPosting,
    IPostItem,
    ITopValueItem,
    IDataSearchPost,
    IResponTopValue,
} from "../../types/home.type";
import { LOCAL_STORAGE, HOMETAB } from "../../utils/contants";
import { ICreateKYCRequest, ISessionKYC } from "../../types/kyc.type";
import { toast } from "react-toastify";
import { ICustomer } from "types/customer.type";
import { getCustomerId } from "../../utils/helpers/authentication";

const initSearchPost: IDataResponseSearchPost = {
    data: {} as any,
    message: "",
};

const initPost: IDataSearchPost = {
    result: true,
    data: [],
    size: 10,
    totalPage: 0,
    page: 1,
    message: "",
    totalData: 0
};

const initState = {
    initSearchPost: initSearchPost,
    isFocus: {} as any,
    dataSearchPost: initPost,
    loading: false,
    bestPosting: {} as IBestPosting,
    hastags: {} as IResponseHastag,
    topValue: [] as ITopValueItem[],
    category: "",
    searchType: 1,
    activeTab: HOMETAB.FORYOU,
    customer: {} as ICustomer,
};

export const searchPost = createAsyncThunk("post/search-post", async (body: any) => {
    const { data } = await instance.post<IDataSearchPost>("/post/search", body);
    return data;
});

export const searchUser = createAsyncThunk("post/search-user", async (body: any) => {
    const response = await instance.post<any>("search/user", body);
    return response.data;
});

const searchSlide = createSlice({
    name: "search",
    initialState: initState,
    reducers: {
        setFocus: (state, actions) => {
            state.isFocus[actions.payload] = true;
        },
        setFalseFocus: (state, actions) => {
            state.isFocus[actions.payload] = false;
        },
        resetFocus: (state) => {
            state.isFocus = {};
        },
        setCategory: (state, actions) => {
            state.category = actions.payload;
        },
        setSearchType: (state, actions) => {
            state.searchType = actions.payload;
        },
        setActiveTab: (state, actions) => {
            state.activeTab = actions.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(searchPost.fulfilled, (state, action) => {
            state.dataSearchPost = action.payload;
        });
    },
});

const searchReducer = searchSlide.reducer;
export const { setFocus, resetFocus, setFalseFocus, setCategory, setSearchType, setActiveTab } = searchSlide.actions;
export default searchReducer;
