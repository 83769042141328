import { LOCAL_STORAGE } from "../contants";

export const getAccessToken = () => {
  const accessToken = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
  return accessToken;
};

export const getCustomerId = () => {
  const accessToken = localStorage.getItem(LOCAL_STORAGE.CUSTOMER_ID);
  return accessToken;
};

export const logOut = () => {
  localStorage.removeItem(LOCAL_STORAGE.ACCESS_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE.CUSTOMER_ID);
};
