import VN from "asset/VN.png";
import KO from "asset/KO.png";
import moment from "moment";

export const handleTrimValue = <T extends Record<string, any>>(initState: T, value: keyof T) => {
    if (typeof initState[value] === "string") {
        initState[value].trim();
    }
};
export const convertPhoneNumber: (value: string) => string = (value: string) => {
    let valueSlice = "";
    if (value.length > 4) {
        valueSlice = value.slice(0, value.length - 4);
        valueSlice = valueSlice + "****";
    } else {
        valueSlice = value;
    }
    return valueSlice;
};

export enum HomeSearchTypeEnum {
    NORMAL = 1,
    CUSTOMER = 2,
    POST = 3,
}

export const TOKEN_KEY = "richcle_token";
export const PHONE_NUMBER = "phone_number";
export const OTP_TOKEN = "otp_token";
export const KEY_SEND_AGAIN = "send_again";
export const KEY_TIMER = "timer";
export const COUNTRY_CODE = "country";
export const LOCAL_STORAGE = {
    CUSTOMER_ID: "customerId",
    ACCESS_TOKEN: "accessToken",
    THEME: "theme",
};

export const SLIDE_OTHER_PROFILE = [
    {
        name: "Finance",
        image: require("asset/finance.png"),
    },
    {
        name: "Transport",
        image: require("asset/transport.png"),
    },
    {
        name: "Production",
        image: require("asset/production.png"),
    },
    {
        name: "Cuisine",
        image: require("asset/cuisine.png"),
    },
    {
        name: "Creative",
        image: require("asset/model.png"),
    },
];

export enum TYPE_PUBLIC_POSTING {
    PUBLIC = 1,
    FOLLOW = 2,
    PAYMENT = 3,
}

export const TYPE_PUBLIC_NAME = [null, "Public", "Follow", "Payment"];

export function convertFileToArrayBuffer(file: File) {
    return new Promise<any>((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => resolve(reader.result));
        reader.addEventListener("error", (err) => reject(err));
        reader.readAsArrayBuffer(file);
    });
}

export const IMAGE_EXTENSION = [".jpg ", "jpeg ", "jfif ", "pjpeg ", "pjp", "png", "svg", "webp"];
export const VIDEO_EXTENSION = [
    "webm",
    "mpg",
    "mp2",
    "mpeg",
    "mpe",
    "mpv",
    "ogg",
    "mp4",
    "m4p",
    "m4v",
    "avi",
    "wmv",
    "mov",
    "qt",
    "flv",
    "swf",
    "mov",
    "MOV",
];

export const getFileType = (url: string) => {
    const fileType = url?.split(".").pop() || "";
    if (IMAGE_EXTENSION.includes(fileType)) {
        return "image";
    } else if (VIDEO_EXTENSION.includes(fileType)) {
        return "video";
    }
    return "";
};

export const CountryOptions = [
    {
        name: "+84",
        value: "+84",
        icon: VN,
    },
    {
        name: "+01",
        value: "+01",
        icon: KO,
    },
];

export const HOMETAB = {
    FORYOU: "For you",
    SUBCRIBING: "Subcribing",
};

export const NOTIFICATION = {
    ALL: "All",
    NOTSEEN: "Not Seen",
};

export const NOTI_TYPE = {
    comment: "Comment",
    like: "Like post",
};

export const VERIFY_KYC_STATUS = {
    UNVERIFIED: -1,
    REJECTED: 0,
    VERIFIED: 1,
    VERIFY_PROCESSING: 2,
};

export const periodTime = (then: any, now: any) => {
    const time = Number(now) - Number(then);
    const nowConvert = new Date(now);
    const thenConvert = new Date(then);

    const showTenDay = 60000 * 60 * 24 * 10;
    const showOneDay = 60000 * 60 * 24 * 1;
    const showOneHour = 60000 * 60 * 1;
    const showOneMinute = 60000;
    
    //The present time
    var datenow = nowConvert.getDate();
    var hournow = nowConvert.getHours();
    var minutenow = nowConvert.getMinutes();
    // Previous time
    var datethen = thenConvert.getDate();
    var hourthen = thenConvert.getHours();
    var minutethen = thenConvert.getMinutes();
    // Compare time
    const d = datenow - datethen;
    const h = hournow - hourthen;
    const mn = minutenow - minutethen;
    let timeAgo: string = "";
    const dateString = thenConvert.getDate() > 9 ? thenConvert.getDate().toString() : "0" + thenConvert.getDate().toString();
    const monthString =
        thenConvert.getMonth() + 1 > 9 ? "/" + (thenConvert.getMonth() + 1).toString() : "/0" + (thenConvert.getMonth() + 1).toString();
    const yearString = "/" + thenConvert.getFullYear().toString();
    if (time > showTenDay) {
        timeAgo = dateString + monthString + yearString;
    } else {
        if (time >= showOneDay) {
            if (time === showOneDay) {
                timeAgo = `${d} day ago`;
            } else {
                timeAgo = `${d} days ago`;
            }
        } else {
            if (time >= showOneHour) {
                if (time === showOneHour) {
                    timeAgo = `${h} hour ago`;
                } else {
                    timeAgo = `${h} hours ago`;
                }
            } else {
                if (time === showOneMinute) {
                    timeAgo = `${mn} minute ago`;
                } else {
                    timeAgo = `${mn} minutes ago`;
                }
            }
        }
    }
    return timeAgo;
};

export const IMAGE_TYPE = ["PNG", "JPEG", "JPG"];
export const VIDEO_TYPE = ["MPEG", "MP4", "MOV", "AVI", "WMV"];

export const convertTime = (time: number) => {
    const format1 = "DD/MM/YYYY HH:mm";
    const date2 = new Date(time);
    return moment(date2).format(format1);
};
