import { combineReducers } from "@reduxjs/toolkit";
import homeReducer from "../page/Home/home.reducer";
import loginReducer from "../page/Login/login.reducer";
import postDetailReducer from "../page/ViewPost/post.reducer";
import postingReducer from "./../page/Posting/post.reducer";
import transactionReducer from "./../page/Transaction/transaction.reducer";
import profileReducer from "./../page/Profile/profile.reducer";
import notificationReducer from "../page/Notification/notification.reducer";
import richcapReducer from "../page/RichCap/richcapConfig.reducer";
import otherReducer from "../page/OtherProfile/otherProfile.reducer";
import verifyPropertyReducer from "../page/VerifyProperty/verify-property.reducer";
import searchReducer from "../page/Search/search.reducer";
const rootReducer = combineReducers({
  homeReducer,
  loginReducer,
  postDetailReducer,
  postingReducer,
  transactionReducer,
  profileReducer,
  notificationReducer,
  richcapReducer,
  verifyPropertyReducer,
  otherReducer,
  searchReducer,
});
export default rootReducer;
