// src/themes/utils.js
export function applyTheme(theme: any) {
    const root = document.documentElement;
    Object.keys(theme).forEach((cssVar) => {
        root.style.setProperty(cssVar, theme[cssVar]);
    });
}

export function createTheme({
    primary,
    secondary,
    textBase,
    thirdColor,
    colorADAFC4,
    color2AB7F1,
    colorBgHashTag,
    colorTextHashTag,
    color2F49CD,
    textDesPost,
    colorBgFocusHashTag,
    textValue,
    textCategory,
    colorModal,
    textClose,
    bgIconModal,
    textTypePosting,
    textDisable,
    colorBgBtnDisable,
    bgNoti,
    bgRead,
    mainNoti,
    lineTab,
    bgItemStatus
}: {
    primary: string;
    secondary: string;
    textBase: string;
    thirdColor: string;
    colorADAFC4: string;
    color2AB7F1: string;
    colorBgHashTag: string;
    colorTextHashTag: string;
    color2F49CD: string;
    textDesPost: string;
    colorBgFocusHashTag: string;
    textValue: string;
    textCategory: string;
    colorModal: string;
    textClose: string;
    bgIconModal: string;
    textTypePosting: string;
    textDisable: string;
    colorBgBtnDisable: string;
    bgNoti: string;
    bgRead: string;
    mainNoti: string;
    lineTab: string;
    bgItemStatus: string;
}) {
    return {
        "--theme-primary": primary,
        "--theme-secondary": secondary,
        "--theme-text-base": textBase,
        "--theme-third-color": thirdColor,
        "--theme-colorADAFC4": colorADAFC4,
        "--theme-color2AB7F1": color2AB7F1,
        "--theme-colorBgHashTag": colorBgHashTag,
        "--theme-colorTextHashTag": colorTextHashTag,
        "--theme-color2F49CD": color2F49CD,
        "--theme-textDesPost": textDesPost,
        "--theme-colorBgFocusHashTag": colorBgFocusHashTag,
        "--theme-textValue": textValue,
        "--theme-textCategory": textCategory,
        "--theme-colorModal": colorModal,
        "--theme-textClose": textClose,
        "--theme-bgIconModal": bgIconModal,
        "--theme-textTypePosting": textTypePosting,
        "--theme-textDisable": textDisable,
        "--theme-colorBgBtnDisable": colorBgBtnDisable,
        "--theme-bgNoti": bgNoti,
        "--theme-bgRead": bgRead,
        "--theme-mainNoti": mainNoti,
        "--theme-lineTab": lineTab,
        "--theme-bgItemStatus": bgItemStatus
    };
}
