
import { IFile, IFileGetURLResponse } from "types/file.type";
import { createService, createServiceNoToken } from "./axios";
import { convertFileToArrayBuffer } from "utils/contants";
import { Buffer } from "buffer";

const instance = createService(process.env.REACT_APP_API_URL);
const instanceNoToken = createServiceNoToken()

const getLinkUploadToS3 = async (fileNames: Array<string>) : Promise<Array<IFileGetURLResponse>> => {
  const url = "/file/create-presigned-url-upload-file";
  const data = {
      fileKeys: fileNames,
  };
  const result = await instance.post(url, data);
  return result.data;
};
const createPresignedURL = async (files: Array<IFile>, operation: string, expires: number) : Promise<Array<IFile>> => {
  const url = "/file/create-presigned-url";
  const data = {
    files: files,
    operation: operation,
    expires: expires
  };
  const result = await instance.post(url, data);
  return result.data;
};

const uploadFileToS3 = async (fileReqs: Array<any>, keyStart: string) : Promise<Array<IFile>> => {
  const files: Array<IFile> = await Promise.all(
    fileReqs.map(async (fileReq) => {
      let filename = keyStart + new Date().getTime().toString() + "_" + fileReq?.name;
      filename = filename.replace(/\s/g, "");
      const arrayBuffer = await convertFileToArrayBuffer(fileReq);
      const buffer = Buffer.from(arrayBuffer);
      let file: IFile = {
        filename: filename,
        contentType: fileReq.type,
        url: filename,
        fieldname: keyStart,
        description: keyStart,
        data: buffer
      }
      return file;
    })
  )
  // const fileNames = files.map(e => {return e.filename})
  const resUrlUploads: Array<IFile> = await createPresignedURL(files, 'putObject', 600);
  const fileResults : Array<IFile> = await Promise.all(
    files.map(async (file) => {
      let resUrlUpload = resUrlUploads.find((e) => e.filename === file.filename);
      if(resUrlUpload) {
        await instanceNoToken.put(resUrlUpload.url, file.data, {
          headers: {
            "Content-Type": file.contentType,
          },
        })
      }
      file.data = null;
      return file;
    })
  )
  
  return fileResults;
};


export {
  getLinkUploadToS3,
  uploadFileToS3
};
